var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"pa-2"},[_vm._v(" "+_vm._s(_vm.evento)+" "),_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-chip',{staticClass:"v-chip--active",attrs:{"color":_vm.evento.colorTipoEvento,"large":"","label":"","outlined":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.evento.iconTipoEvento))]),_vm._v(" "+_vm._s(_vm.evento.tipoEvento)+" ")],1),_c('v-chip',{staticClass:"v-chip--active ml-2",attrs:{"color":_vm.evento.colorEstadoEvento,"large":"","label":"","outlined":""}},[_c('v-icon',{attrs:{"left":""}},[_vm._v(_vm._s(_vm.evento.iconEstadoEvento))]),_vm._v(" "+_vm._s(_vm.evento.estado)+" ")],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('tarjeta-datos',{attrs:{"titulo":"Detalles evento","icon":"calendar-edit","datos":[
					{nombre: 'Fecha inicio', valor: _vm.parseDate(_vm.evento.fechaInicioEvento)},
					{nombre: 'Horario', valor: (_vm.evento.horario)},
					...[
						{nombre: 'Novios', valor: (_vm.evento.novios)},
						{nombre: 'Enlace civil', valor: (_vm.evento.enlaceCivil) ? 'Sí' : 'No'},
						{nombre: 'Tipo boda', valor: (_vm.evento.tipoBoda)},
					].filter(() => _vm.evento.tipoEvento == 'Boda'),
					...[
						{nombre: 'Niño', valor: (_vm.evento.nino)},
						{nombre: 'Padres', valor: (_vm.evento.padres)},
					].filter(() => _vm.evento.tipoEvento == 'Comunión')
				]}})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('tarjeta-datos',{attrs:{"titulo":"Detalles lugar","icon":"map-marker","datos":[
					{nombre: 'Espacio', valor: (_vm.evento.nombreEspacio)},
					{nombre: 'Salón', valor: (_vm.evento.nombreSalon)},
					
					{nombre: 'Nº Adultos', valor: (_vm.evento.nAdultos)},
					{nombre: 'Nº Niños', valor: (_vm.evento.nNinos)},
					{nombre: 'Tcos.', valor: (_vm.evento.tcos)},
				]}})],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }